import React, { Component } from 'react';
import {Link} from 'react-router-dom'; 
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import $ from '../node_modules/jquery/dist/jquery.js';
import './App.css';
import welcome from './images/1.jpg';
import characterA from './images/corporate.png';
import characterB from './images/2-1.png';
import characterC from './images/corporate1.png';
import logo from './images/logo.png';
import logo1 from './images/logo-1.png';
import banner from './images/GMOFL.jpg';
const parsleyjs = require('parsleyjs') ;





class App extends Component {

constructor(props){
  super(props);

       this.state={
           image:"",
           welcome:true,
           privacy:false,
           form:false,
           acknowledge:false,
           welcomeText:"",
           character:"",
           characterwidth:"",
           firstname:"",
           lastname:"",
           othername :"",
           gender:"",
           pinAvailability:"",
           employeraddress:"",
           employername:"",
           currentpfa:"",
           rsapin:"",
           mobileno1:"",
           mobileno2:"",
           email:"",
           rsaBand:"A",
           formname:""
       }
         this.switchToPrivacyComponents = this.switchToPrivacyComponents.bind(this)
         this.switchToWelcomeComponent = this.switchToWelcomeComponent.bind(this)
         this.switchToFormComponent = this.switchToFormComponent.bind(this)
         this.revealPinField = this.revealPinField.bind(this)
         this.revealMobileNoField = this.revealMobileNoField.bind(this)
         this.parsley = this.parsley.bind(this)
         this.generateUUID = this.generateUUID.bind(this)
         this.crossSiteScriptProtocol = this.crossSiteScriptProtocol.bind(this)
         this.alternativeSubmitResponse = this.alternativeSubmitResponse.bind(this)
  };


 componentDidMount(){ 
  

    let formNameDetails = this.generateUUID()
    console.log(formNameDetails)
      this.setState({ 
          image:welcome,
          character:characterA,
          characterwidth:"540",
          welcomeText:"I'm Confident",
          formname: formNameDetails
      })
      //   this.revealPinField()
        this.revealMobileNoField()
      

  }



 generateUUID() {

         let d = new Date().getTime();
         let uuid =
 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
             let r = (d + Math.random() * 16) % 16 | 0;
             d = Math.floor(d / 16);
             return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
         });

       let  formname = "023WEB"  + uuid.toUpperCase().substring(0, 8) +
 uuid.toUpperCase().substring(10, 11);
         
         
         return formname;

     }
     







revealPinField(){
   //function handling the customer segement validation 

            var currentPfa =  document.getElementById('cpfa').value; 
            console.log(currentPfa); 

    this.setState({
           currentpfa: currentPfa
       }, () => { 
            console.log(this.state.currentpfa)
       
       })
   
  
               var currentPfa =  document.getElementById('cpfa').value; 
            console.log(currentPfa); 
        
            if (currentPfa ==="not registered"  || currentPfa === "Cant remember"){   
         
            }else{

              $('#cpfa1').fadeOut('slow', function(){ 
                  $('#pinno').fadeIn('slow')
              })  


                $('#switchPFA').click(function(){ 
            console.log('switch')
                   $('#pinno').fadeOut('slow', function(){ 
                  $('#cpfa1').fadeIn('slow')

                  
              })              
           })
                       
              return false;
            }
               

}


alternativeSubmitResponse(){
 
   $('#submit1').click(function(){ 
    
     alert('please remove special characters')

   })
}





 nativeSubmitResponse(){
 
   $('#submit').click(function(){ 
    console.log('i ran')
            var pen = " "
            var penNumber = " "
            var penLength = 0 
            var currentPfa = document.getElementById('cpfa').value;   
            var currentPfaPin = document.getElementById('cpfaPin').value;  
            var currentGender = document.getElementById('genderselection').value

            if(currentPfaPin.length < 0){
            var penstring = document.getElementById('pin1').value
            var pen = penstring.substring(0, 3);
            var penNumber = penstring.substring(3, 15)
            var penLength = penNumber.length 
            }
            
            console.log(pen)   
            console.log(penNumber)
            console.log(penLength)
            console.log(currentPfaPin)
            console.log(currentGender)
        // function validating dob is not before or equals to doe 
            

if((currentPfa === 'not registered' || currentPfa === 'Cant remember') && currentGender !== "Select Gender" )  {
          

          if(currentPfaPin === '0'){
            $('#cpfa').val(this.state.currentpfa)
          }
if($('#form-length').parsley().validate({group: 'interest-block', force: true})) { 

 console.log(currentPfaPin)

    if($('.parsley-error').length === 0){
     //start of data submission


     //collate data for API submission

     alert('Thank you for choosing Leadway Pensure')

      


  let interestObject = { 
             "firstname": this.state.firstname,
          "lastname": this.state.lastname,
          "middlename": this.state.othername,
          "gender": this.state.gender,
          "mobilenoA": this.state.mobileno1,
          "mobilenoB": this.state.mobileno2,
          "email": this.state.email,
          "organization": this.state.employername,
          "orgaddress": this.state.employeraddress,
          "currentpfa": currentPfa,
          "rsaband": this.state.rsaBand,
          "rsapin": this.state.rsapin
}


       console.log(interestObject)


       const submitInterestObject = fetch('https://surecal.leadway-pensure.com/prospect/enrollwebsiteprospect', { 
            method:"POST",
            body:JSON.stringify(interestObject),
            headers:{
              "content-type" : "application/json"
            },
            mode:"cors",
            credentials:"include"
        })
        .then(response => response.json())
                .then(response => { 

          if(!response){
          
              alert('its us not you, submit form again please :) ')
          }

          if(response){
                      const getFormContentContainer = document.getElementById('form-data');
               const getAcknowledgeContentContainer = document.getElementById('acknowledge');
               $(getFormContentContainer).fadeOut('fast',()=>{   
               $(getAcknowledgeContentContainer).fadeIn('slow')
   
                   })
          }
        })
  


//end of data submission
    }else{
      alert('Kindly fill all details')
    }
    
  }else{
    alert('not valid')
  }

         }  else {

    console.log(pen)   
    console.log(penNumber)
    console.log(penLength)
    console.log(currentPfaPin)

   


          if(currentPfaPin === '0'){
            $('#cpfa').val(this.state.currentpfa)
            console.log($('#cpfa').val(this.state.currentpfa))
          }

    if(currentPfa !== 'Cant remember' || currentPfa !== 'Not registered'){
     console.log('ranning')
      if( penLength === 0 && (currentGender === "Male" || currentGender === "Female") ){ 
         console.log('ranning')
             if($('#form-length').parsley().validate({group: 'interest-block', force: true})) {
 console.log('ranning')
   console.log($('.parsley-error').length)
    var ok = $('.parsley-error').length === 0;
    if(!ok){
          return alert('please fill all fields')
    }
// start data push
    

    if($('.parsley-error').length === 0){
     //start of data submission


     //collate data for API submission

     alert('Thank you for choosing Leadway Pensure')

let interestObject = { 
             "firstname": this.state.firstname,
          "lastname": this.state.lastname,
          "middlename": this.state.othername,
          "gender": this.state.gender,
          "mobilenoA": this.state.mobileno1,
          "mobilenoB": this.state.mobileno2,
          "email": this.state.email,
          "organization": this.state.employername,
          "orgaddress": this.state.employeraddress,
          "currentpfa": currentPfa,
          "rsaband": this.state.rsaBand,
          "rsapin": this.state.rsapin
}



       console.log(interestObject)


       const submitInterestObject = fetch('https://surecal.leadway-pensure.com/prospect/enrollwebsiteprospect', { 
            method:"POST",
            body:JSON.stringify(interestObject),
            headers:{
              "content-type" : "application/json"
            },
            mode:"cors",
            credentials:"include"
        })
        .then(response => response.json())
                .then(response => { 

          if(!response){
          
              alert('its us not you, submit form again please :) ')
          }

          if(response){
                      const getFormContentContainer = document.getElementById('form-data');
               const getAcknowledgeContentContainer = document.getElementById('acknowledge');
               $(getFormContentContainer).fadeOut('fast',()=>{   
               $(getAcknowledgeContentContainer).fadeIn('slow')
   
                   })
          }
        })
  
  


//end of data submission
    }
}else{
  alert('ensure all fields are filled')
}

//  .on('form:submit', function() {
//    return false; // Don't submit form for this demo
//  });
}else if(penLength > 0 && currentGender !== 'Select Gender'){

   if(pen == "pen"){ 
       return alert('use capital letters for RSA PIN')
    }
        console.log('rann')
 if(penLength === 12 && pen == "PEN"){


    if($('#form-length').parsley().validate({group: 'interest-block', force: true})) {

    var ok = $('.parsley-error').length === 0;
    if(!ok){
          return alert('please fill all fields')
    }

   if($('.parsley-error').length === 0){
     //start of data submission


     //collate data for API submission

     alert('Thank you for choosing Leadway Pensure')

     let interestObject = { 
          "firstname": this.state.firstname,
          "lastname": this.state.lastname,
          "middlename": this.state.othername,
          "gender": this.state.gender,
          "mobilenoA": this.state.mobileno1,
          "mobilenoB": this.state.mobileno2,
          "email": this.state.email,
          "organization": this.state.employername,
          "orgaddress": this.state.employeraddress,
          "currentpfa": currentPfa,
          "rsaband": this.state.rsaBand,
          "rsapin": this.state.rsapin
}


       console.log(interestObject)

     
      const submitInterestObject = fetch('https://surecal.leadway-pensure.com/prospect/enrollwebsiteprospect', { 
            method:"POST",
            body:JSON.stringify(interestObject),
            headers:{
              "content-type" : "application/json"
            },
            mode:"cors",
            credentials:"include"
        })
        .then(response => response.json())
                .then(response => { 
             console.log(response)
          if(!response){
          
              alert('its us not you, submit form again please :) ')
          }

          if(response){
                      const getFormContentContainer = document.getElementById('form-data');
               const getAcknowledgeContentContainer = document.getElementById('acknowledge');
               $(getFormContentContainer).fadeOut('fast',()=>{   
               $(getAcknowledgeContentContainer).fadeIn('slow')
   
                   })
          }
        })
  

//end of data submission
    }
    
  



  }

      }else{
        alert('check pin format. e.g PEN0123456789012')
      }




    }else{

      console.log('ran last')

   return alert('please fill all fields')

}


      }
    }
  })
}


revealMobileNoField(event){

console.log('i ran')
    // function handling mobile switch
    
      $('#mobno1').change(function(){ 
          
           var mobLength = document.getElementById('mobno1').value
            console.log(mobLength.length)
            if(mobLength.length >= 11 ){
                
                $('#mob1').fadeOut('slow', function(){ 
                  $('#mob2').fadeIn('slow')
              })

            } 
          
          
      })


          $('#switchMobNo').click(function(){ 
                   $('#mob2').fadeOut('slow', function(){ 
                  $('#mob1').fadeIn('slow')
              })

               
           })
           
}



crossSiteScriptProtocol(relevantData){ 


 console.log('script ran')
  const splitData = relevantData.split('')
  console.log(splitData)

  for(let i = 0; i < splitData.length ; i++){
    console.log(splitData[i])
      
      if(splitData[i] === '.' || splitData[i] === "<" || splitData[i] === ">" || splitData[i] === "?" || splitData[i] === "*"  || splitData[i] === "&" || splitData[i] === "%" || splitData[i] === "$" || splitData[i] === "#" ){

          let submitButton = document.getElementById('submit')

          alert('remove punctuation')

       function disabledClass(){ 

            $(submitButton).addClass( "disabled" )
            submitButton.id = "submit1"

        }

      $.when(disabledClass()).done(this.alternativeSubmitResponse())

      }else{
        let submitButton = document.getElementById('submit1')
        if(submitButton){

          function enabledClass(){ 
               submitButton.id = "submit"
         $(submitButton).removeClass( "disabled" ) 
          }
          $.when(enabledClass()).done(this.nativeSubmitResponse())
        }

      }
  }

}



updateMobileNo1Field = (event) => {
   console.log('mobile ran')
    this.setState({
           mobileno1: event.target.value
       }, () => { 

  this.crossSiteScriptProtocol(this.state.mobileno1)
       })


}

updateMobileNo2Field = (event) => {
    this.setState({
           mobileno2: event.target.value
       }, () => { 

  this.crossSiteScriptProtocol(this.state.mobileno2)
       })
}

updateFirstNameField = (event) => {
    this.setState({
           firstname: event.target.value
       }, () => { 

  this.crossSiteScriptProtocol(this.state.firstname)
       })
}

updateSurnameField = (event) => {
    this.setState({
           lastname: event.target.value
       }, () => { 

  this.crossSiteScriptProtocol(this.state.lastname)
       })
}

updateOthernameField = (event) => {
    this.setState({
           othername: event.target.value
       }, () => { 

  this.crossSiteScriptProtocol(this.state.othername)
       })
}

updateGenderField = (event) => {
    this.setState({
           gender: event.target.value
       }, () => { 

  this.crossSiteScriptProtocol(this.state.gender)
       })
}


updateCurrentPfaField = (event) => {
   this.revealPinField()
  // this.updateRsapin()
  console.log('field')
   
}

updatePinAvailability = (event) => {
    this.setState({
           pinAvailability: event.target.value
       })

  }

updateEmployernameField = (event) => {
    this.setState({
           employername: event.target.value
       }, () => { 

 // this.crossSiteScriptProtocol(this.state.employername)
       })
}

updateEmployeraddressField = (event) => {
    this.setState({
           employeraddress: event.target.value
       }, () => { 

//  this.crossSiteScriptProtocol(this.state.employeraddress)
       })
}

updateRsaBand = (event) => {
  this.setState({
    rsaBand : event.target.value
  }, () => { 
console.log(this.state.rsaBand)
  this.crossSiteScriptProtocol(this.state.rsaBand)
       })
}

updateRsapin = (event) => {
  this.setState({
    rsapin : event.target.value
  }, () => { 

  this.crossSiteScriptProtocol(this.state.rsapin)
       })
}

updateEmailField = (event) =>{
    this.setState({
           email: event.target.value
       })
}
  

parsley(){

console.log('i ran')
            var pen = " "
            var penNumber = " "
            var penLength = 0 
            var currentPfa = document.getElementById('cpfa').value;   
            var currentPfaPin = document.getElementById('cpfaPin').value;  
            var currentGender = document.getElementById('genderselection').value

            if(currentPfaPin.length < 0){
            var penstring = document.getElementById('pin1').value
            var pen = penstring.substring(0, 3);
            var penNumber = penstring.substring(3, 15)
            var penLength = penNumber.length 
            }
            
            console.log(pen)   
            console.log(penNumber)
            console.log(penLength)
            console.log(currentPfaPin)
            console.log(currentGender)
        // function validating dob is not before or equals to doe 
            

if((currentPfa === 'not registered' || currentPfa === 'Cant remember') && currentGender !== "Select Gender" )  {
          

          if(currentPfaPin === '0'){
            $('#cpfa').val(this.state.currentpfa)
          }
if($('#form-length').parsley().validate({group: 'interest-block', force: true})) { 

 console.log(currentPfaPin)

    if($('.parsley-error').length === 0){
     //start of data submission


     //collate data for API submission

     alert('Thank you for choosing Leadway Pensure')

    let interestObject = { 
          "firstname": this.state.firstname,
          "lastname": this.state.lastname,
          "middlename": this.state.othername,
          "gender": this.state.gender,
          "mobilenoA": this.state.mobileno1,
          "mobilenoB": this.state.mobileno2,
          "email": this.state.email,
          "organization": this.state.employername,
          "orgaddress": this.state.employeraddress,
          "currentpfa": currentPfa,
          "rsaband": this.state.rsaBand,
          "rsapin": this.state.rsapin
}




       console.log(interestObject)

      const submitInterestObject = fetch('https://surecal.leadway-pensure.com/prospect/enrollwebsiteprospect', { 
            method:"POST",
            body:JSON.stringify(interestObject),
            headers:{
              "content-type" : "application/json"
            },
            mode:"cors",
            credentials:"include"
        })
        .then(response => response.json())
                .then(response => { 

          if(!response){
          
              alert('its us not you, submit form again please :) ')
          }

          if(response){
               const getFormContentContainer = document.getElementById('form-data');
               const getAcknowledgeContentContainer = document.getElementById('acknowledge');
               $(getFormContentContainer).fadeOut('fast',()=>{   
               $(getAcknowledgeContentContainer).fadeIn('slow')
   
                   })
          }
        })
  


  


//end of data submission
    }else{
      alert('Kindly fill all details')
    }
    
  }else{
    alert('not valid')
  }

         }  else {

    console.log(pen)   
    console.log(penNumber)
    console.log(penLength)
    console.log(currentPfaPin)

   


          if(currentPfaPin === '0'){
            $('#cpfa').val(this.state.currentpfa)
            console.log($('#cpfa').val(this.state.currentpfa))
          }

    if(currentPfa !== 'Cant remember' || currentPfa !== 'Not registered'){
     console.log('ranning')
      if( penLength === 0 && (currentGender === "Male" || currentGender === "Female") ){ 
         console.log('ranning')
             if($('#form-length').parsley().validate({group: 'interest-block', force: true})) {
 console.log('ranning')
   console.log($('.parsley-error').length)
    var ok = $('.parsley-error').length === 0;
    if(!ok){
          return alert('please fill all fields')
    }
// start data push
    

    if($('.parsley-error').length === 0){
     //start of data submission


     //collate data for API submission

     alert('Thank you for choosing Leadway Pensure')


   let interestObject = { 
         
          "firstname": this.state.firstname,
          "lastname": this.state.lastname,
          "middlename": this.state.othername,
          "gender": this.state.gender,
          "mobilenoA": this.state.mobileno1,
          "mobilenoB": this.state.mobileno2,
          "email": this.state.email,
          "organization": this.state.employername,
          "orgaddress": this.state.employeraddress,
          "currentpfa": currentPfa,
          "rsaband": this.state.rsaBand,
          "rsapin": this.state.rsapin
}


       console.log(interestObject)


     const submitInterestObject = fetch('https://surecal.leadway-pensure.com/prospect/enrollwebsiteprospect', { 
            method:"POST",
            body:JSON.stringify(interestObject),
            headers:{
              "content-type" : "application/json"
            },
            mode:"cors",
            credentials:"include"
        })
        .then(response => response.json())
                .then(response => { 

          if(!response){
          
              alert('its us not you, submit form again please :) ')
          }

          if(response){
                      const getFormContentContainer = document.getElementById('form-data');
               const getAcknowledgeContentContainer = document.getElementById('acknowledge');
               $(getFormContentContainer).fadeOut('fast',()=>{   
               $(getAcknowledgeContentContainer).fadeIn('slow')
   
                   })
          }
        })
  


  


//end of data submission
    }
}else{
  alert('ensure all fields are filled')
}

//  .on('form:submit', function() {
//    return false; // Don't submit form for this demo
//  });
}else if(penLength > 0 && currentGender !== 'Select Gender'){

   if(pen == "pen"){ 
       return alert('use capital letters for RSA PIN')
    }
        console.log('rann')
 if(penLength === 12 && pen == "PEN"){


    if($('#form-length').parsley().validate({group: 'interest-block', force: true})) {

    var ok = $('.parsley-error').length === 0;
    if(!ok){
          return alert('please fill all fields')
    }

   if($('.parsley-error').length === 0){
     //start of data submission


     //collate data for API submission

     alert('Thank you for choosing Leadway Pensure')

   
  let interestObject = { 
                    "firstname": this.state.firstname,
          "lastname": this.state.lastname,
          "middlename": this.state.othername,
          "gender": this.state.gender,
          "mobilenoA": this.state.mobileno1,
          "mobilenoB": this.state.mobileno2,
          "email": this.state.email,
          "organization": this.state.employername,
          "orgaddress": this.state.employeraddress,
          "currentpfa": currentPfa,
          "rsaband": this.state.rsaBand,
          "rsapin": this.state.rsapin
}


       console.log(interestObject)

   const submitInterestObject = fetch('https://surecal.leadway-pensure.com/prospect/enrollwebsiteprospect', { 
            method:"POST",
            body:JSON.stringify(interestObject),
            headers:{
              "content-type" : "application/json"
            },
            mode:"cors",
            credentials:"include"
        })
        .then(response => response.json())
                .then(response => { 

          if(!response){
          
              alert('its us not you, submit form again please :) ')
          }

          if(response){
                      const getFormContentContainer = document.getElementById('form-data');
               const getAcknowledgeContentContainer = document.getElementById('acknowledge');
               $(getFormContentContainer).fadeOut('fast',()=>{   
               $(getAcknowledgeContentContainer).fadeIn('slow')
   
                   })
          }
        })
  
//end of data submission
    }
    
  



  }

      }else{
        alert('check pin format. e.g PEN0123456789012')
      }




    }else{

      console.log('ran last')

   return alert('please fill all fields')

}


      }
    }
  }
           



switchToPrivacyComponents(){ 
 
       $('html, body').animate({scrollTop:0}, 'slow')

      this.setState({ 
          welcome:false,
          privacy:true,
          character:characterB,
           characterwidth:"579",
          welcomeText:"... Not so fast !"
       }, () => { 


           if(this.state.welcome === false && this.state.privacy === true){
              const getWelcomeContentContainer = document.getElementById('welcome')
              const getPrivacyContentContainer = document.getElementById('data-privacy')
              const getCharacterContentContainer = document.getElementById('characterSlot')

              getCharacterContentContainer.setAttribute('src', this.state.character)

              $("#confidence").css({"margin-top":"32%"});
              const adbarElement = document.getElementById('ad-bar')
              const subtextElement = document.getElementById('sub-text')

               $(adbarElement).fadeOut('fast', ()=>{   
               $(subtextElement).fadeOut('fast')
             })

   $(getWelcomeContentContainer).fadeOut('fast',()=>{   
               $(getPrivacyContentContainer).fadeIn('slow')
           


           })


      }

  })
}


    switchToWelcomeComponent(){ 
 
         $('html, body').animate({scrollTop:0}, 'slow')

      this.setState({ 
          welcome:true,
          privacy:false,
          character:characterA,
           characterwidth:"540",
          welcomeText:"I'm Confident"
       }, () => { 


           if(this.state.welcome === true && this.state.privacy === false){

              const getWelcomeContentContainer = document.getElementById('welcome')
              const getPrivacyContentContainer = document.getElementById('data-privacy')
              const getCharacterContentContainer = document.getElementById('characterSlot')

              getCharacterContentContainer.setAttribute('src', this.state.character)

              $("#confidence").css({"margin-top":"25%"});
              const adbarElement = document.getElementById('ad-bar')
              const subtextElement = document.getElementById('sub-text')
              $(adbarElement).fadeIn('fast', ()=>{   
               $(subtextElement).fadeIn('fast')
             })

         
             $(getPrivacyContentContainer).fadeOut('fast',()=>{   
               $(getWelcomeContentContainer).fadeIn('slow')


             })

           } 
      })




  }


   switchToFormComponent(){

   $('html, body').animate({scrollTop:0}, 'slow')

      this.setState({ 
          welcome:false,
          privacy:false,
          form:true,
          character:characterC,
            characterwidth:"579",
           welcomeText:"I'm Optimistic"
       }, () => { 

           if(this.state.welcome === false && this.state.privacy === false && this.state.form === true){
              const getPrivacyContentContainer = document.getElementById('data-privacy');
              const getFormContentContainer = document.getElementById('form-data');
              const interactiveColumn = document.getElementById('interactive-column');
              const getCharacterContentContainer = document.getElementById('characterSlot')

              getCharacterContentContainer.setAttribute('src', this.state.character)

               $("#confidence").css({"margin-top":"32%"})

              const helloLogo = document.getElementById('hello-logo');
              const shadow = document.getElementsByClassName('border-rad');
                $("#confidence").css({"margin-top":"32%"});
              const adbarElement = document.getElementById('ad-bar')
              const subtextElement = document.getElementById('sub-text')
                       $(adbarElement).fadeIn('fast', ()=>{   
               $(subtextElement).fadeIn('fast')
             })

              helloLogo.setAttribute('src', logo)

              $(interactiveColumn).removeClass( "white-bg" ).addClass( "black-bg" );
              $(shadow).css({"box-shadow":"none"});

             $(getPrivacyContentContainer).fadeOut('fast',()=>{   
               $(getFormContentContainer).fadeIn('slow')
             })

           } 
      })

   }


render() { 

        return (
       
          <div className="App">
          <div id="horizontal">
  <img src={banner} width="100%" />

</div>



<div id="vertical">
    <div className="row full-width mOff" id="">




<div className="bs-callout bs-callout-info hidden">
  <h4>Yay!</h4>
  <p>Everything seems to be ok :)</p>
</div>
          <div className="col-12 col-lg-8" style={{ backgroundImage: `url(${this.state.image})`, backgroundSize:"cover", backgroundPosition:"center center", backgroundRepeat:"no-repeat" }} >
          <div className="row">
         <div className="col-5 text-center">
             <img src={this.state.character} width={this.state.characterwidth} id="characterSlot" />
         </div> 
          <div className="col-7">

             <h1 className="accent-font">   </h1>
             <span className="accent-font top-apostrophe"> " </span> 

             <h1 className="accent-font text-right mbOff" id="confidence"> {this.state.welcomeText} </h1>
              <hr className="line-width-a white-bg mOff ml-auto" id="ad-bar" />
              <h6 className="accent-font text-right standard-margin-top-2" id="sub-text"> <span className="white-text"> Because</span> I'm pensure </h6>
               
                   <span className="accent-font bottom-apostrophe"> " </span> 
           </div>
              </div>
             </div>
                <div className="col-12 col-lg-4 white-bg standard-padding-rl" id="interactive-column">
                     
                  <div className="text-right col-12 standard-margin-top-2">
                     <img src={logo1} width="90" id="hello-logo" />
                  </div>

                  <div className="col-12 white-bg border-rad standard-margin-top card-height" id="welcome"> 


                  <div className="entrance standard-padding-tb standard-padding-rl ">

                  <h3 className="accent-font burnt-orange text-left">
                         Dear Customer,
                  </h3>

                     <h3 className="accent-font text-center mOff">
                        Expression of interest
                  </h3>

                  <hr className="line-width mx-auto orange-bg mOff" />
                <h6 className="standard-margin-top body-font">
                The transfer season is here and we can't wait to welcome you into Leadway Pensure PFA.
                <br/>
    
                   By filling this form you are registering your interest to join our community of future savers!
                 

                </h6>

                <div className="col-12 text-center standard-margin-top">

                <button className="btn white-text button-style accent-font" onClick={this.switchToPrivacyComponents} id="join"> Join the leading team </button>

                </div>

                  </div>
                    
                  </div> 



 
                   <div className="col-12 white-bg border-rad standard-margin-top" id="data-privacy"> 


                  <div className="entrance standard-padding-tb standard-padding-rl ">

                     <h3 className="accent-font text-center mOff burnt-orange">
                        Data Protection Policy
                  </h3>
                  <hr className="line-width mx-auto orange-bg mOff" />

                     <div id="data-privacy-display ">
                <h6 className="standard-margin-top body-font">
              Leadway Pensure PFA is strongly committed to protecting your privacy. The information in this document is collected from you primarily to make for easier and more rewarding administrative purposes. You have the right to give and also withdraw your consent to the collection and processing of your information at any time. By filling this form, you agree that the information you provide can be used for the stated purposes. For further information, please visit 
              </h6>

              <a href="https://leadway-pensure.com/privacy/"> Privacy policy </a> 

                </div>

                <div className="col-12 text-center standard-margin-top">

                <button className="btn white-text button-style accent-font" onClick={this.switchToFormComponent}> I accept </button>

                </div>
                <div className="col-12 text-center standard-margin-top">

                 <button className="btn white-text button-style accent-font" onClick={this.switchToWelcomeComponent} > I do not accept </button>

                </div>

                  </div>
                    
                  </div> 

                   <div className="col-12 white-bg border-rad standard-margin-top" id="form-data"> 
                        <div className="bs-callout bs-callout-warning">

                          <p>This form seems to be invalid please cross check details :(</p>
                         </div>
                         <div className="bs-callout bs-callout-info">
                           <p>Everything seems to be ok :)</p>
                         </div>

                  <div className="entrance standard-padding-tb-equal standard-padding-rl" >

                     <h5 className="accent-font text-center mOff burnt-orange">
                       Fill your details and we will get back to you shortly!
                  </h5>
                 
                 <form id="form-length" data-parsley-validate> 
                 <div className="form-group text-center">
                        <label className="blue-text accent-font">Last name</label>
                          <input autoplay="false"
                            type="text" data-parsley-group="interest-block" data-parsley-required data-parsley-error-message="Enter surname" value={this.state.lastname} onChange={this.updateSurnameField} data-parsley-minlength="3" data-parsley-maxlength="20" className="form-border form-control form-border input-field-width mx-auto standard-round-box-curve body-font" id="lastname" aria-describedby="emailHelp" placeholder="Lastname" required=""/>
                        </div>
                      <div className="form-group text-center">
                        <label className="blue-text accent-font">First name</label>
                          <input autoplay="false" type="text" data-parsley-error-message="Enter firstname" data-parsley-group="interest-block" value={this.state.firstname} onChange={this.updateFirstNameField} data-parsley-minlength="3" data-parsley-maxlength="20" className="form-border form-control form-border input-field-width mx-auto standard-round-box-curve body-font" id="firstname" aria-describedby="emailHelp" placeholder="First name" data-parsley-required/>
                        </div>
                        
                         <div className="form-group text-center">
                        <label className="blue-text accent-font">Other name</label>
                          <input autoplay="false" type="text" value={this.state.othername} onChange={this.updateOthernameField} data-parsley-minlength="3" data-parsley-maxlength="20" className="form-border form-control form-border input-field-width mx-auto standard-round-box-curve body-font" id="othername" aria-describedby="emailHelp" placeholder="Other name" required=""/>
                        </div>
                        <div className="form-group text-center" id="gender">
                        <label className="blue-text accent-font">Gender</label>
                                <select data-parsley-required data-parsley-error-message="Please fill" data-parsley-group="interest-block" onChange={this.updateGenderField} value={this.state.gender} className="form-control language col-sm-7 mx-auto currentPFA body-font" id="genderselection" required="">
                                    <option> Select gender </option>
                                    <option value="Male"> Male</option>
                                    <option value="Female"> Female </option>
                                </select>
                                </div>
                         <div className="form-group text-center">
                        <label className="blue-text accent-font">RSA Band </label>
                        <select value={this.state.rsaBand} onChange={this.updateRsaBand} data-parsley-required data-parsley-error-message="Select a band" data-parsley-group="interest-block" className="form-control language col-sm-7 mx-auto currentPFA body-font" id="rsaBand" >
                                   <option value="A" id="default">Below 1mil </option>
                                    <option value="B" id="default">1mil - 5mil </option>
                                    <option value="C"> 5mil - 10mil </option>
                                     <option value="D"> 10mil - 20mil </option>
                                     <option value="E"> 20mil and above </option>
                        </select>
                         </div>
                         <div className="form-group text-center">
                        <label className="blue-text accent-font">Employer name</label>
                          <input autoplay="false" type="text" data-parsley-required data-parsley-error-message="Please fill" data-parsley-group="interest-block" value={this.state.employername} onChange={this.updateEmployernameField} data-parsley-minlength="3" data-parsley-maxlength="30" className="form-border form-control form-border input-field-width mx-auto standard-round-box-curve body-font" id="employername" aria-describedby="emailHelp" placeholder="Employer name" required=""/>
                        </div>
                         <div className="form-group text-center">
                        <label className="blue-text accent-font">Employer address</label>
                          <input autoplay="false" type="text" data-parsley-required data-parsley-error-message="Please fill" data-parsley-group="interest-block" value={this.state.employeraddress} onChange={this.updateEmployeraddressField} data-parsley-minlength="3" data-parsley-maxlength="100" className="form-border form-control form-border input-field-width mx-auto standard-round-box-curve body-font" id="employeraddress" aria-describedby="emailHelp" placeholder="Employer address" required=""/>
                        </div>
                         <div className="form-group text-center" id="cpfa1">
                        <label className="blue-text accent-font">Current PFA</label>
                                <select data-parsley-required data-parsley-error-message="Please fill" onChange={this.updateCurrentPfaField} value={this.state.currentpfa} className="form-control language col-sm-7 mx-auto currentPFA body-font" id="cpfa" required="">
                                    <option value="" id="default"> Select Provider</option>
                                    <option value="not registered"> Not registered </option>
                                     <option value="Cant remember"> Can't Remember PFA </option>
                                    
                                    <option value="APT Pension Managers Limited"> APT Pension Managers Limited </option>
                                    <option value="ARM Pension Fund Managers Limited"> ARM Pension Fund Managers Limited </option>
                                    <option value="AXA MANSARD pension"> AXA MANSARD Pension </option>
                                    <option value="Crusader sterling pensions Limited"> Crusader Sterling Pensions Limited </option>
                                    <option value="Fidelity Pension Managers"> Fidelity Pension Managers </option>
                                    <option value="First Guarantee Pension Limited"> First Guarantee Pension Limited </option>
                                    <option value="Future Unity Glanvils Pensions Limited"> Future Unity Glanvils Pensions Limited </option>
                                    <option value="Investment one pension manahers Limited"> Investment One Pension Managers Limited </option>
                                    <option value="IEI-Anchor pension managers Limited"> IEI-Anchor Pension Managers Limited </option>
                                    <option value="IGI Pension fund managers Limited"> IGI Pension Fund Managers Limited </option>
                                    <option value="Legacy pension managers Limited"> Legacy Pension Managers Limited </option>
                                    <option value="NLPC pension fund administrators Limited"> NLPC Pension Fund Administrators Limited </option>
                                    <option value="NPF pensions Limited"> NPF Pensions Limited </option>
                                    <option value="OAK Pensions Limited"> OAK Pensions Limited </option>
                                    <option value="Pensions Alliance Limited"> Pensions Alliance Limited</option>
                                    <option value="Premium pension Limited"> Premium Pension Limited </option>
                                    <option value="Sigma Pensions Limited"> Sigma Pensions Limited </option>
                                    <option value="Stanbic IBTC Pension Managers"> Stanbic IBTC Pension Managers </option>
                                    <option value="Trustfund Pensions PLC"> Trustfund Pensions PLC </option>
                                </select>
                                
                        </div>

                        <div className="form-group" id='pinno'>
                                      <label className="field-label accent-font blue-text"> RSA Pin </label>
                                         <small id="emailHelp" className="form-text text-muted">Leave Blank If Unavailable.</small>
                                              <input autoplay="false" data-parsley-excluded="true"  type="text" onChange={this.updateRsapin} value={this.state.rsapin}  data-parsley-maxlength="15" className="form-control col-sm-7 language mx-auto text-center pin body-font" placeholder="e.g PEN123456789012" id='cpfaPin' />
                                              <h6 className="burnt-orange" id="switchPFA"> change PFA </h6> 
                          
                                 
                                
                                              
                        </div>

                         <div className="form-group text-center" id="mob1">
                        <label className="blue-text accent-font">Mobile No(Line 1)</label>
                          <input type="Number" data-parsley-required data-parsley-error-message="Mobile number less than 11 digits" data-parsley-group="interest-block"  onChange={this.updateMobileNo1Field} value={this.state.mobileno1} data-parsley-minlength="11" data-parsley-maxlength="11" className="form-control form-border input-field-width mx-auto standard-round-box-curve body-font" id="mobno1" aria-describedby="emailHelp" placeholder="e.g 08012345678" />
                        </div>
                          <div className="form-group body-font" id='mob2'>
                  
                                      <label className="field-label blue-text accent-font"> Mobile No(Line 2)  </label>
                                <input type="Number" data-parsley-error-message="Mobile number less than 11 digits" data-parsley-group="interest-block" value={this.state.mobileno2} onChange={this.updateMobileNo2Field} data-parsley-group="block2" data-parsley-minlength="11" data-parsley-maxlength="11" className="form-border form-control col-sm-7 language mx-auto text-center mobile body-font" data-parsley-group="interest-block" data-parsley-minlength="11" data-parsley-maxlength="15" id='mobno2' placeholder="e.g 08012345678"/>
                                <h6 className="burnt-orange" id="switchMobNo"> Change mobile 1 </h6>
                                
                  </div>
                         <div className="form-group text-center">
                        <label className="blue-text accent-font">Email Address</label>
                          <input className="body-font" type="email" data-parsley-type="email" data-parsley-required data-parsley-error-message="Min length 10, Max length 40" data-parsley-group="interest-block" value={this.state.email} onChange={this.updateEmailField} data-parsley-minlength="10" data-parsley-maxlength="40" className="form-control form-border input-field-width mx-auto standard-round-box-curve body-font" id="email" aria-describedby="emailHelp" placeholder="Email"/>
                        </div>
                         
                     </form>


                <div className="col-12 text-center standard-margin-top">

              <input type="submit" className="btn button-style accent-font white-text" id="submit" value="Submit" onClick={this.parsley} />
              
                    <h6 className="accent-font text-center mOff burnt-orange">
                       Leadway Pensure PFA 2020
                  </h6>

                </div>


                  </div>
                    
                  </div> 



                  <div className="col-12 white-bg border-rad standard-margin-top card-height" id="acknowledge"> 


                  <div className="entrance standard-padding-tb standard-padding-rl ">

                  <h1 className="accent-font burnt-orange text-left">
                         Dear {this.state.firstname},
                  </h1>

                     <h3 className="accent-font text-center mOff burnt-orange ">
                           Your details have been saved!

                                       </h3>

                <h6 className="standard-margin-top body-font">
                 We have recieved your details. A member of our relationship team will be in touch with you shortly to discuss the 
                       start of your financial welfare with Leadway Pensure PFA

                </h6>

                <div className="col-12 text-center standard-margin-top">

            <a href="https://leadway-pensure.com">    <button className="btn white-text button-style accent-font" > Back to Leadway-pensure.com </button>
            </a>
                </div>

                  </div>  
                  </div>


 <div className="row full-width mOff">
               <div className="col-12 promise text-center standard-margin-top-3">
                  <h4 className="accent-font burnt-orange"> Your Future, Our Passion </h4>
               </div>

             </div>

             </div>
</div>
            
          </div>
         
          
          </div>
     
        );
      }
}


export default App;
 